import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileUploader } from 'ng2-file-upload/ng2-file-upload';
import { OptionService } from '../../services/option.service';
import { OptionModel } from '../../models/option.model';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-relevant-technical-data',
  templateUrl: './relevant-technical-data.component.html',
  styleUrls: ['./relevant-technical-data.component.scss']
})
export class RelevantTechnicalDataComponent implements OnInit {

  @Input() relevantTechnicalDataToModel: any;
  @Output() messageEvent = new EventEmitter<any>();
  RTDMessage: any;

  switchboard: OptionModel[];
  externalConnectivity: OptionModel[];
  remoteConnectionType: OptionModel[];
  internalResourcesConnectivity: OptionModel[];

  relevantTechnicalDataForm: FormGroup;

  fileUpload: File[] = null;

  constructor(private optionService: OptionService, private formBuilder: FormBuilder) {

    // options of switchboard
    this.optionService.getByType(12).subscribe((resp: any) => {
      if (resp.ok) {
        this.switchboard = resp.options;
      }
    });

    // options of externalConnectivity
    this.optionService.getByType(13).subscribe((resp: any) => {
      if (resp.ok) {
        this.externalConnectivity = resp.options;
      }
    });

    // options of remoteConnectionType
    this.optionService.getByType(14).subscribe((resp: any) => {
      if (resp.ok) {
        this.remoteConnectionType = resp.options;
      }
    });

    // options of internalResourcesConnectivity
    this.optionService.getByType(15).subscribe((resp: any) => {
      if (resp.ok) {
        this.internalResourcesConnectivity = resp.options;
      }
    });


  }

  ngOnInit() {
    this.relevantTechnicalDataForm = this.formBuilder.group({
      switchboard_id: [''],
      external_connectivity_id: [''],
      gb_contracted: [''],
      operator: [''],
      server: [''],
      router: [''],
      ip_order_tranck: [''],
      external_ip_address: [''],
      remote_connection_type_id: [''],
      technical_data: [''],
      internal_resources_connectivity_id: [''],
      version_switchboard: [''],
    });

    this.initValuesForm();
  }

  setFile(files: File[]) {
    this.fileUpload = !files ? null : files;
  }

  changeForm(formGroup) {
    this.RTDMessage = formGroup.value;
    this.messageEvent.emit(this.RTDMessage);
  }

  /**
     * Inicializamos los valores del formulario.
     */
  initValuesForm() {
    const controls: { [key: string]: AbstractControl } = this.relevantTechnicalDataForm.controls;

    controls.switchboard_id.setValue(this.relevantTechnicalDataToModel.switchboard_id);
    controls.external_connectivity_id.setValue(this.relevantTechnicalDataToModel.external_connectivity_id);
    controls.gb_contracted.setValue(this.relevantTechnicalDataToModel.gb_contracted);
    controls.operator.setValue(this.relevantTechnicalDataToModel.operator);
    controls.server.setValue(this.relevantTechnicalDataToModel.server);
    controls.router.setValue(this.relevantTechnicalDataToModel.router);
    controls.ip_order_tranck.setValue(this.relevantTechnicalDataToModel.ip_order_tranck);
    controls.external_ip_address.setValue(this.relevantTechnicalDataToModel.external_ip_address);
    controls.remote_connection_type_id.setValue(this.relevantTechnicalDataToModel.remote_connection_type_id);
    controls.technical_data.setValue(this.relevantTechnicalDataToModel.technical_data);
    controls.internal_resources_connectivity_id.setValue(this.relevantTechnicalDataToModel.internal_resources_connectivity_id);
    controls.version_switchboard.setValue(this.relevantTechnicalDataToModel.version_switchboard);

  }

}
