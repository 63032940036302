import { Injectable } from '@angular/core';
import { DocumentModel } from '../models/document.model';
import { HttpClient } from '@angular/common/http';
import { ConfigApi } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    private URL_API: string;

    constructor(private http: HttpClient, private config: ConfigApi) {
        this.URL_API = config.URL_SERVICES + '/document/';
    }

    getDocuments() {
        return this.http.get(this.URL_API, this.config.OPTIONS_HTTP);
    }

    getDocumentsCustomer(id: number) {
        return this.http.get(this.URL_API + 'customer/' + id, this.config.OPTIONS_HTTP);
    }

    getDocumentId(id: number) {
        return this.http.get(this.URL_API + id, this.config.OPTIONS_HTTP);
    }

    getDataCategory() {
        return this.http.get(this.URL_API + 'category', this.config.OPTIONS_HTTP);
    }

    add(document: DocumentModel) {
        return this.uploadFile(document, 'POST');
    }

    update(document: DocumentModel) {
        return this.uploadFile(document, 'PUT');
    }

    delete(id: number) {
        return this.http.delete(this.URL_API + id, this.config.OPTIONS_HTTP);
    }

    private uploadFile(document: DocumentModel, type: string) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            const xhr = new XMLHttpRequest();

            for (const key of Object.keys(document)) {
                formData.append(key, document[key]);
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(JSON.parse(xhr.response));
                    }
                }
            };

            const url = this.URL_API + (document.id !== null ? document.id : '');

            xhr.open(type, url, true);
            xhr.setRequestHeader('Authorization', this.config.PREFIX + ' ' + localStorage.getItem('token'));
            xhr.send(formData);

        });
    }

}
