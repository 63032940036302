import { Injectable } from '@angular/core';
import { UserModel } from '../models/user.model';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigApi } from '../config/config';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map'
import { RememberFilterService } from './remember-filter.service';

export const USER_ROL = {
    ADMIN: 1,
    COMMERCIAL: 2,
    DISRIBUTOR: 3, // canal indiecto
    TECHNIC: 4,
    BACKOFFICE: 5,
}

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    private userApp: UserModel;
    private token: string;

    constructor(private router: Router, private http: HttpClient, private config: ConfigApi,
        private rememberFilter: RememberFilterService) {
        this.loadStorage();
    }

    private loadStorage() {
        this.token = localStorage.getItem('token');
        if (this.token) {
            this.userApp = JSON.parse(localStorage.getItem('user'));
        } else {
            this.userApp = null;
            this.token = '';
        }
    }

    login(email, password) {
        return this.http.post(this.config.URL_SERVICES + '/login', {email: email, password: password})
            .map( (res: any) => {
                if (res.ok) {
                    // localStorage.setItem('id', res.id);
                    localStorage.setItem('token', res.token);
                    localStorage.setItem('user', JSON.stringify(res.user));
                    this.userApp = res.user;
                    this.token = res.token;
                    this.config.OPTIONS_HTTP = {
                        headers: new HttpHeaders({
                            'Content-Type':  'application/json',
                            'Authorization': this.config.PREFIX + ' ' + this.token
                        })
                    };
                    this.rememberFilter.resetData();
                }

                return res.ok;
            })
            .catch( err => {
                return [err.ok];
            });
    }

    logout() {
        this.userApp = null;
        this.token = '';

        localStorage.removeItem('token');
        localStorage.removeItem('user');
        // localStorage.removeItem('id');

        this.router.navigate(['/auth']);
    }

    isLoggedIn() {
        return this.token.length > 10 && this.verifytoken();
    }

    /**
     * Verifica que el token sea válido porqué no esté expirado.
     */
    private verifytoken(): boolean {
        const token = this.token;
        const payload = JSON.parse( atob( token.split('.')[1] ));

        const tokenExp = new Date( payload.exp  * 1000 );
        const _now = new Date();

        _now.setTime( _now.getTime() + ( 1 * 60 * 60 * 1000 ) );

        return  tokenExp.getTime() > _now.getTime();
    }

    getUserApp(): UserModel {
        return this.userApp;
    }

    getUsers() {
        return this.http.get(this.config.URL_SERVICES + '/user/', this.config.OPTIONS_HTTP);
     }

    getUsersByType(rol_id: number) {
        return this.http.get(this.config.URL_SERVICES + '/user/type/' + rol_id, this.config.OPTIONS_HTTP);
     }

    getUserId (id: number) {
        return this.http.get(this.config.URL_SERVICES + '/user/' + id, this.config.OPTIONS_HTTP);
    }

    update(user: UserModel) {
        return this.http.put(this.config.URL_SERVICES + '/user/' + user.id, user, this.config.OPTIONS_HTTP);
    }

    add(user: UserModel) {
        return this.http.post(this.config.URL_SERVICES + '/user/', user, this.config.OPTIONS_HTTP);
    }



}
