import { Injectable } from '@angular/core';
import { OpportunityModel } from '../models/opportunity.model';
import { ToDoModel } from '../models/todo.model';
import { HttpClient } from '@angular/common/http';
import { ConfigApi } from '../config/config';


@Injectable({
    providedIn: 'root'
})
export class OpportunityService {

    private URL_API: string;

    constructor(private http: HttpClient, private config: ConfigApi) {
        this.URL_API = config.URL_SERVICES + '/opportunity/';
    }

    getOpportunityId(id: number) {
        return this.http.get(this.URL_API + id, this.config.OPTIONS_HTTP);
    }

    add(models: any) {
        return this.http.post(this.URL_API, models, this.config.OPTIONS_HTTP);
    }

    update(opportunity: any) {
        return this.http.put(this.URL_API + opportunity.id, opportunity, this.config.OPTIONS_HTTP);
    }

    updateState(opportunity: any, state: any) {
        return this.http.put(this.URL_API + opportunity.id, state, this.config.OPTIONS_HTTP);
    }

    getOpportunities() {
        return this.http.get(this.URL_API, this.config.OPTIONS_HTTP);
    }

    getOpportunitiesCustomer(customer_id: number) {
        return this.http.get(this.URL_API + 'customer/' + customer_id, this.config.OPTIONS_HTTP);
    }

    getOpportunitiesWithBussiness() {
        return this.http.get(this.URL_API + '/data', this.config.OPTIONS_HTTP);
    }
}
