import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ConfigApi {

    URL_SERVICES = environment.url_api;
    PREFIX = 'PIPPOL';
    OPTIONS_HTTP: {headers: HttpHeaders}

    constructor() {
        const token = localStorage.getItem('token');
        if (token) {
            this.OPTIONS_HTTP = {
                headers: new HttpHeaders({
                    'Content-Type':  'application/json',
                    'Authorization': this.PREFIX + ' ' + localStorage.getItem('token')
                })
            };
        }
    }
}
