import { Injectable } from '@angular/core';
import { ToDoModel } from '../models/todo.model';
import { HttpClient } from '@angular/common/http';
import { ConfigApi } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class ToDoService {

  private URL_API: string;

  constructor(private http: HttpClient, private config: ConfigApi) {
  		this.URL_API = config.URL_SERVICES + '/ToDo/';
   }

   getToDoId(id: number) {
        return this.http.get(this.URL_API + id, this.config.OPTIONS_HTTP);
    }

    add(ToDo: ToDoModel) {
      return this.http.post(this.URL_API, ToDo, this.config.OPTIONS_HTTP);
    }

    update(ToDo: ToDoModel) {
        return this.http.put(this.URL_API + ToDo.id, ToDo, this.config.OPTIONS_HTTP);
    }

    getToDos(){
        return this.http.get(this.URL_API, this.config.OPTIONS_HTTP);
    }

    delete(ToDo: ToDoModel){
        return this.http.delete(this.URL_API + ToDo.id, this.config.OPTIONS_HTTP);
    }

}
