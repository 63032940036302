import { Injectable } from '@angular/core';
import { DelegationModel } from '../models/delegation.model';
import { HttpClient } from '@angular/common/http';
import { ConfigApi } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class DelegationService {
    private URL_API: string;

    constructor(private http: HttpClient, private config: ConfigApi) {
        this.URL_API = config.URL_SERVICES + '/delegation/';
    }

    add(delegation: DelegationModel) {
        return this.http.post(this.URL_API, delegation, this.config.OPTIONS_HTTP);
    }

    update(delegation: DelegationModel) {
        return this.http.put(this.URL_API + delegation.id, delegation, this.config.OPTIONS_HTTP);
    }

    getDelegationView(id_delegation: number) {
        return this.http.get(this.URL_API + 'view/' + id_delegation, this.config.OPTIONS_HTTP);
    }

    getDelegations() {
        return this.http.get(this.URL_API, this.config.OPTIONS_HTTP);
    }

    delete(delegation: DelegationModel) {
        return this.http.delete(this.URL_API + delegation.id, this.config.OPTIONS_HTTP);
    }

    getDelegationsByCustomerId(idCustomer: number) {
        return this.http.get(this.URL_API + 'customer/' + idCustomer, this.config.OPTIONS_HTTP);
    }
}
