import { Injectable } from '@angular/core';

interface ModuleRemember {
    filter: any;
}

@Injectable({
    providedIn: 'root'
})
export class RememberFilterService {

    private modules: {
        opportunity: ModuleRemember,
        customer: ModuleRemember,
        order: ModuleRemember,
        backoffice: ModuleRemember,
        incidence: ModuleRemember,
        commission: ModuleRemember,
        document: ModuleRemember,
        user: ModuleRemember
    }

    constructor() {
        this.getStorage();
        if (this.modules === null) {
            this.resetData();
            this.setStorage();
        }
     }

    /**
     * Guarda los datos actuales del módulo indicado.
     * @param module {string} El nombre del módulo.
     * @param remember {ModuleRemember} La página y el filtro actual del módulo.
     */
    public setData(module: string, remember: ModuleRemember) {
        this.modules[module] = remember;
        this.setStorage();
    }

    /**
     * Retorna los datos actuales del módulo indicado.
     * @param module {string} El nombre del módulo.
     * @return ModuleRemember Los datos del módulo.
     */
    public getData(module: string): ModuleRemember {
        return this.modules[module];
    }

    /**
     * Reinicia los valores a null.
     */
    resetData() {
        this.modules = {
            opportunity: {filter: null},
            customer: {filter: null},
            order: {filter: null},
            backoffice: {filter: null},
            incidence: {filter: null},
            commission: {filter: null},
            document: {filter: null},
            user: {filter: null}
        }
    }

    /**
     * Guarda los datos de los módulos en el localStorage.
     */
    private setStorage() {
        localStorage.setItem('modules', JSON.stringify(this.modules));
    }

    /**
     * Recupera los datos de los módulos del localStorage.
     */
    private getStorage() {
        this.modules = JSON.parse(localStorage.getItem('modules'));
    }
}
