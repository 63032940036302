import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigApi } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class OptionService {

    private URL_API: string;

    constructor(private http: HttpClient, private config: ConfigApi) {
        this.URL_API = config.URL_SERVICES + '/option/';
    }

    getByType(type: number) {
        return this.http.get(this.URL_API + 'type/' + type, this.config.OPTIONS_HTTP);
    }

    getByTypeAll(type: number) {
        return this.http.get(this.URL_API + 'typeall/' + type, this.config.OPTIONS_HTTP);
    }

    getOptions() {
        return this.http.get(this.URL_API, this.config.OPTIONS_HTTP);
    }

}
