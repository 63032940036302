import { Component, Input, OnChanges, SimpleChange, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-datatable',
    templateUrl: './datatable.component.html',
    styles: []
})
export class DatatableComponent implements OnChanges {

    @Input() allRows: any;
    @Input() columns: any;
    @Input() nameTable: string;
    @Input() rowClass: Function = null;
    @Input() limit = 10;
    @Input() offset = 0;

    @Output() selectedRow = new EventEmitter<any>();
    @Output() onPage = new EventEmitter<any>();

    public count = 0;
    public scrollBarHorizontal = (window.innerWidth < 1200);
    public rows: any[] = [];

    model: any[];

    constructor(protected router: Router) {
        window.onresize = () => {
            this.scrollBarHorizontal = (window.innerWidth < 1200);
        };
    }

    ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
        if (typeof changes.allRows.currentValue !== 'undefined' && !changes.allRows.firstChange) {
            this.count = this.allRows.length;
            this.page();
        };
    }

    private page() {
        this.onPage.emit(this.offset);
        const start = this.offset * this.limit;
        const end = start + this.limit > this.count ? this.count : start + this.limit;
        const rows = [];
        for (let i = start; i < end; i++) {
            rows.push(this.allRows[i]);
        }
        this.rows = rows;
    }

    public onPageEvent(pageInfo) {
        this.offset = pageInfo.offset;
        this.limit = pageInfo.limit;
        this.page();
    }
}
