import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-button-spinner',
    templateUrl: './button-spinner.component.html',
    styleUrls: ['./button-spinner.componente.scss']
})
export class ButtonSpinnerComponent {

    @Input() disabled: boolean;
    @Input() text: string;
    @Input() type: string;
    @Input() spinner: boolean;

    constructor() { }

    clickButton(event) {
    }

}
