import { Router } from '@angular/router';

export abstract class Baselist {

    public filterRows: any[];
    public count = 0;
    public offset = 0;
    public limit = 10;
    public rows: any[];

    abstract setDataStorage(page: number);

    constructor(protected urlOnSelect: string, protected router: Router) { }

    page() {
        const start = this.offset * this.limit;
        const end = start + this.limit > this.count ? this.count : start + this.limit;
        const _rows = [];

        for (let i = start; i < end; i++) {
            if (this.filterRows.hasOwnProperty(i)) {
                _rows.push(this.filterRows[i]);
            }
        }
        this.setDataStorage(this.offset);
        this.rows = _rows;
    }

    initRowsFilter() {
        this.count = this.filterRows.length;
        this.page();
    }

    onPage(pageInfo) {
        this.offset = pageInfo.offset;
        this.limit = pageInfo.limit;
        this.page();
    }

    onSelect(row) {
        this.router.navigate( [ this.urlOnSelect, row['selected'][0].id] );
    }
}
