import { Injectable } from '@angular/core';
import { UsersService, USER_ROL } from '../../services/users.service';

export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    subchildren?: SuperChildrenItems[];
}

export interface SuperChildrenItems {
    state: string;
    name: string;
    type?: string;
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    children?: ChildrenItems[];
}

const HORIZONTALMENUITEMS = [
    {
        state: 'home',
        name: 'Inicio',
        type: 'link',
        icon: 'home'
    },
    {
        state: 'opportunities',
        name: 'Oportunidades',
        type: 'sub',
        icon: 'star',
        children: [
            { state: 'opportunities/opportunitiesedit', name: 'Nueva oportunidad' },
        ]
    },
    {
        state: 'customers',
        name: 'Clientes',
        type: 'sub',
        icon: 'people',
        children: [
            { state: 'customers/customersedit', name: 'Nuevo cliente' },
        ]
    },
    {
        state: 'orders',
        name: 'Pedidos',
        type: 'sub',
        icon: 'local_grocery_store',
        children: [
            { state: 'orders/orderstrunkedit', name: 'Trunk' },
            { state: 'orders/orderscloudedit', name: 'Cloud' },
            { state: 'orders/ordersgsmedit', name: 'GSM' },
            { state: 'orders/orderservicesedit', name: 'Otros Servicios' }
        ]
    },
    {
        state: 'install',
        name: 'Backoffice',
        type: 'sub',
        icon: 'assignment',
        children: [
            { state: 'install', name: 'Back-Office' },
            { state: 'install/product', name: 'Agregar producto' },
            { state: 'install/product-list', name: 'Administrar productos' },
        ]
    },
    {
        state: 'incidences',
        name: 'Incidencias',
        type: 'sub',
        icon: 'error',
        children: [
            { state: 'incidences/incidenceedit', name: 'Nueva incidencia' },
        ]
    },
    {
        state: 'tools',
        name: 'Herramientas',
        type: 'sub',
        icon: 'build',
        children: [
            { state: 'tools/workingpart', name: 'Parte de trabajo' },
            { state: 'tools/configurator/budgets', name: 'Configurador' },
        ]
    },
    {
        state: 'commissions',
        name: 'Comisiones',
        type: 'sub',
        icon: 'attach_money',
        children: [
            { state: 'commissions/commissionsedit', name: 'Nueva comision' },
        ]
    },
    {
        state: 'documents',
        name: 'Documentos',
        type: 'sub',
        icon: 'file_copy',
        children: [
            { state: 'documents/documentsedit', name: 'Nuevo documento' },
        ]
    },
    {
        state: 'users',
        name: 'Usuarios',
        type: 'sub',
        icon: 'account_circle',
        children: [
            { state: 'users/usersedit', name: 'Nuevo usuario' },
        ]
    },

    // {
    //     state: 'discount',
    //     name: 'Discounts',
    //     type: 'sub',
    //     icon: 'account_circle',
    //     children: [
    //         { state: 'discount/discountedit', name: 'Add Discount' },
    //     ]
    // }

];

@Injectable()
export class HorizontalMenuItems {

    private MENUITEMS: any[];
    private MENUUSERS: any = {
        'backoffice': ['home', 'customers', 'orders', 'install', 'incidences', 'tools', 'commissions'],
        'distributor': ['home', 'opportunities', 'customers', 'orders', 'tools', 'commissions', 'documents'],
        'commercial': ['home', 'opportunities', 'customers', 'orders', 'incidences', 'tools', 'documents'],
        'technic': ['home', 'customers', 'install', 'incidences', 'tools']
    }

    constructor(public usersService: UsersService) { }

    public generateMenu() {
        const userApp: any = this.usersService.getUserApp();

        if (userApp.rol_id === USER_ROL.ADMIN) {
            this.MENUITEMS = HORIZONTALMENUITEMS;
        } else if (userApp.rol_id === USER_ROL.BACKOFFICE) {
            this.MENUITEMS = HORIZONTALMENUITEMS.filter(this.filterMenuByUser('backoffice').bind(this));
        } else if (userApp.rol_id === USER_ROL.TECHNIC) {
            HORIZONTALMENUITEMS[2].type = 'link';
            this.MENUITEMS = HORIZONTALMENUITEMS.filter(this.filterMenuByUser('technic').bind(this));
        } else if (userApp.rol_id === USER_ROL.DISRIBUTOR) {
            this.MENUITEMS = HORIZONTALMENUITEMS.filter(this.filterMenuByUser('distributor').bind(this));
        } else if (userApp.rol_id === USER_ROL.COMMERCIAL) {
            this.MENUITEMS = HORIZONTALMENUITEMS.filter(this.filterMenuByUser('commercial').bind(this));
        }
    }

    /**
     * Función callback del filtro de los puntos de menú según los permisos de cada perfil.
     * @param {sring} profile El nombre del perfil.
     * @return {boolean} Retorna true si el punto de menú pertenece al perfil indicado.
     */
    filterMenuByUser(profile: string) {
        return (m: any) => this.MENUUSERS[profile].includes(m.state);
    }

    getAll(): Menu[] {
        return this.MENUITEMS;
    }
    add(menu: Menu) {
        // HORIZONTALMENUITEMS.push(Menu);
    }
}
