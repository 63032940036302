import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { UsersService, USER_ROL } from './users.service';
import { UserModel } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class SocketClientService {

    static socket: SocketIOClient.Socket = null;
    private userApp: UserModel;

    constructor(private userService: UsersService) { }

    /**
     * Inicializamos el socket para los usuarios correspondientes
     */
    public initSockect() {
        this.userApp = this.userService.getUserApp();

        // Abrimos el socket sólo para los usuarios tipo Backoffice y Técnico
        if ([USER_ROL.ADMIN, USER_ROL.BACKOFFICE, USER_ROL.TECHNIC].includes(this.userApp.rol_id)) {
            SocketClientService.socket = SocketClientService.socket === null ?
            io(environment.url_api, {transports: ['polling']}) : SocketClientService.socket;
        }
    }

    /**
     * Observable que retorna las cantidades de notificaciones según son instalaciones o incidencias.
     */
    public getNotification() {
        return Observable.create((observer) => {
            if (SocketClientService.socket !== null) {
                SocketClientService.socket.on('notification', (noti) => {
                    observer.next(this.filterNotifications(noti));
                });
            }
        });
    }

    /**
     * Observable que retorna las notificaciones de incidencias facturables.
     */
    public getAlerts() {
        return Observable.create((observer) => {
            if (SocketClientService.socket !== null) {
                SocketClientService.socket.on('billableInvoice', (notis) => {
                    observer.next(notis);
                });
            }
        })
    }

    /**
     * Envía el evento de las notificaciones leídas.
     * @param notis La relación de notificaciones leídas por usuario.
     */
    public setNotificationsReaded(notis: any) {
        if (SocketClientService.socket !== null) {
            SocketClientService.socket.emit('notisReaded', notis);
        }
    }

    /**
     * Desconecta el socket actual.
     */
    public disconnect() {
        if (SocketClientService.socket !== null) {
            SocketClientService.socket.disconnect();
            SocketClientService.socket = null
        }
    }

    /**
     * Filtra las notificaciones recibidas según el tipo de usuario.
     * @param noti
     */
    private filterNotifications(noti: any) {
        const countInsta = this.userApp.rol_id === USER_ROL.ADMIN ?
        noti.installation.step1 + noti.installation.step2 + noti.installation.step3 :
        (this.userApp.rol_id === USER_ROL.BACKOFFICE ? noti.installation.step1 + noti.installation.step3 : noti.installation.step2);
        const incidences: any[] = noti.incidence.technics.filter(t => t.user_assign_id === this.userApp.id).map(t => t.length);
        return {
            installation: countInsta,
            incidence: incidences
        }
    }
}
