export class ToDoModel {

	constructor(
		public id: number  = null,
		public date_create: string  = null,
		public subject: string,
		public date_todo: string,
		public message: string = null,
		public complete: number,
		public user_id: number ,
		public opportunity_id: number,
		public customer_id: number,
	){}

}
