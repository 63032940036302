import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { OptionService } from '../../services/option.service';
import { OptionModel } from '../../models/option.model';
import { UsersService } from '../../services/users.service';
import { UserModel } from '../../models/user.model';



@Component({
  selector: 'app-hiring-information',
  templateUrl: './hiring-information.component.html',
  styles: [],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-br' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
})
export class HiringInformationComponent implements OnInit {

  @Input() hiringInformationToModel: any;
  @Output() messageEvent = new EventEmitter<any>();
  hiringForm: FormGroup;
  hiringMessage: any;
  userApp: UserModel;
  redOption = false;

  _maintenance_id: OptionModel[];
  _trunk_sip_id: OptionModel[];
  _cp_model_id: OptionModel[];


  constructor(private adapter: DateAdapter<any>, private formBuilder: FormBuilder, private optionService: OptionService,
    private usersService: UsersService) {

    this.adapter.setLocale('es-es');
    this.userApp = usersService.getUserApp();

    //options of maintenance
    this.optionService.getByType(10).subscribe((resp: any) => {
      if (resp.ok) {
        this._maintenance_id = resp.options;
      }
    });

    //options of trunk_sip_id
    this.optionService.getByType(11).subscribe((resp: any) => {
      if (resp.ok) {
        this._trunk_sip_id = resp.options;
      }
    });

    //options of cp_model_id
    this.optionService.getByType(9).subscribe((resp: any) => {
      if (resp.ok) {
        this._cp_model_id = resp.options;
      }
    });

  }

  ngOnInit() {
    this.hiringForm = this.formBuilder.group({
      n_trunk_cloud: [''],
      package_trunk: [''],
      cp_customer: [''],
      cp_model_id: [''],
      cp_version: [''],
      trunk_sip_id: [''],
      date_create_service: [''],
      cp_value: [''],
      method_pay: [''],
      monthly_fee: [''],
      renting_contract: [''],
      date_start_contract: [''],
      date_end_contract: [''],
      maintenance_id: [''], 
    });

    this.initValuesForm();
  }

  changeForm(formGroup) {
    this.hiringMessage = formGroup.value;
    this.redOption = [122, 123].includes(this.hiringMessage.maintenance_id);
    this.messageEvent.emit(this.hiringMessage);
  }

  /**
     * Inicializamos los valores del formulario.
     */
  initValuesForm() {
    const controls: { [key: string]: AbstractControl } = this.hiringForm.controls;

    controls.n_trunk_cloud.setValue(this.hiringInformationToModel.n_trunk_cloud);
    controls.package_trunk.setValue(this.hiringInformationToModel.package_trunk);
    controls.cp_customer.setValue(this.hiringInformationToModel.cp_customer);
    controls.cp_model_id.setValue(this.hiringInformationToModel.cp_model_id);
    controls.cp_version.setValue(this.hiringInformationToModel.cp_version);
    controls.trunk_sip_id.setValue(this.hiringInformationToModel.trunk_sip_id);
    controls.date_create_service.setValue(this.hiringInformationToModel.date_create_service);
    controls.cp_value.setValue(this.hiringInformationToModel.cp_value);
    controls.method_pay.setValue(this.hiringInformationToModel.method_pay);
    controls.monthly_fee.setValue(this.hiringInformationToModel.monthly_fee);
    controls.renting_contract.setValue(this.hiringInformationToModel.renting_contract);
    controls.date_start_contract.setValue(this.hiringInformationToModel.date_start_contract);
    controls.date_end_contract.setValue(this.hiringInformationToModel.date_end_contract);
    controls.maintenance_id.setValue(this.hiringInformationToModel.maintenance_id);

    this.redOption = [122, 123].includes(controls.maintenance_id.value);

  }

}
