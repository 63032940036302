import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html'
})
export class PopupComponent implements OnInit {

  title: string;
  message: string;
  btn1: string;
  btn2: string;

  constructor(public dialogRef: MatDialogRef<PopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.title = this.data.title;
    this.message = this.data.message;
    this.btn1 = this.data.btn1;
    this.btn2 = this.data.btn2;
  }

}
