import { NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import { HorizontalMenuItems } from './menu-items/horizontal-menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { ToggleFullscreenDirective } from './fullscreen/toggle-fullscreen.directive';
import { TabHistoryComponent } from './tab-history/tab-history.component';
import { FormsModule } from '@angular/forms';
import { MatOptionModule, MatSelectModule, MatButtonModule, MatProgressSpinnerModule, MatListModule,
    MatDialogModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DatatableComponent } from './datatable/datatable.component';
import { ButtonSpinnerComponent } from './button-spinner/button-spinner.component';
import { ListAddressOrderComponent } from './list-address-order/list-address-order.component';
import { ViewDelegationComponent } from './view-delegation/view-delegation.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        FlexLayoutModule,
        MatOptionModule,
        MatSelectModule,
        MatButtonModule,
        MatCardModule,
        MatDatepickerModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatListModule,
        MatDialogModule,
        NgxDatatableModule
    ],
    declarations: [
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        ToggleFullscreenDirective,
        TabHistoryComponent,
        DatatableComponent,
        ButtonSpinnerComponent,
        ListAddressOrderComponent,
        ViewDelegationComponent
    ],
    exports: [
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        ToggleFullscreenDirective,
        TabHistoryComponent,
        DatatableComponent,
        ButtonSpinnerComponent,
        ListAddressOrderComponent,
        ViewDelegationComponent
    ],
    entryComponents: [
        ViewDelegationComponent
    ],
    providers: [MenuItems, HorizontalMenuItems]
})
export class SharedModule { }
