import { Component, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import { Inject } from '@angular/core';
import { MatSnackBar, MatSelectChange, MatDialog, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerModel } from '../../../models/customer.model';
import { CommunitiesService } from '../../../services/communities.service';

@Component({
  selector: 'app-view-customer-order',
  templateUrl: './view-customer-order.component.html',
  styles: []
})
export class ViewCustomerOrderComponent implements OnInit {
	
	formCustomerEdit: FormGroup;
    customerModel: CustomerModel;
    nombreCliente: string;
    business_name: string;
    nif: string;
    acronym: string;
    direction: string;
    city: string;
    cp: string;
    provincie: string;
    contact_phone: string;
    contact_mobile: string;
    attorney: string;
    nif_attorney: string;
    iban: string;
    bic: string;
    contact_email: string;
    showIban: boolean;

  constructor(public dialogRef: MatDialogRef<ViewCustomerOrderComponent>, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: any, private communitiesService: CommunitiesService) { 
        
       this.customerModel = data[0];
       let province = this.communitiesService.provinces.find(p => p.id === this.customerModel.province_id);
       this.nombreCliente = this.customerModel.contact_person;
       this.business_name = this.customerModel.business_name;
       this.nif = this.customerModel.nif;
       this.acronym = this.customerModel.acronym;
       this.direction = this.customerModel.direction;
       this.city = this.customerModel.city;
       this.cp = this.customerModel.cp;
       this.provincie = typeof province !== 'undefined' ? province.name : '';
       this.contact_mobile = this.customerModel.contact_mobile;
       this.contact_phone = this.customerModel.contact_phone;
       this.attorney = this.customerModel.attorney;
       this.nif_attorney = this.customerModel.nif_attorney;
       this.iban = this.customerModel.iban;
       this.contact_email = this.customerModel.contact_email;

       this.showIban = data.hasOwnProperty('showIban') ? data.showIban : true;
  }

  ngOnInit() {
  	this.formCustomerEdit =  this.formBuilder.group({
  });
  }

}
