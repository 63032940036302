import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { HorizontalMenuItems } from '../../shared/menu-items/horizontal-menu-items';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import {TranslateService} from '@ngx-translate/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { PerfectScrollbarConfigInterface,
PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

import { UsersService, USER_ROL } from '../../services/users.service';
import { ActivatedRoute } from '@angular/router';
import { UserModel } from '../../models/user.model';
import { SocketClientService } from '../../services/socket-client.service';
import { InstallationModel } from '../../models/installation.model';
import { MatDrawer } from '@angular/material';

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit, OnDestroy {

  private _router: Subscription;
  USER_ROL = USER_ROL;

  /** recoger el usuario para las directivas de seguridad */
  userModel: UserModel;
  userId: number;
  userRol: number;

  today: number = Date.now();
  url: string;
  showSettings = false;
  dark: boolean;
  boxed: boolean;
  collapseSidebar: boolean;
  compactSidebar: boolean;
  sidebarBg = false;
  currentLang = 'en';
  layoutDir = 'ltr';
  
  menuLayout           : any = 'top-menu';
  selectedSidebarImage : any = 'bg-1';
  selectedSidebarColor : any = 'sidebar-white';
  selectedHeaderColor  : any = 'header-default';
  collapsedClass       : any = 'side-panel-opened';

  notiBack = 0;
  notiIncidence = 0;
  notifications = [];
  notiCount = 0;

  @ViewChild('end') end: MatDrawer;
  @ViewChild('sidemenu') sidemenu;

  public config: PerfectScrollbarConfigInterface = {};

  constructor(private router: Router, public menuItems: MenuItems, public horizontalMenuItems : HorizontalMenuItems,
    public translate: TranslateService, private activatedRoute: ActivatedRoute, private usersService: UsersService,
    private socket: SocketClientService ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

      /** recoger el usuario para las directivas de seguridad */
      const user: UserModel = usersService.getUserApp();
      this.userId = user.id;
      this.userRol = user.rol_id;

      this.initSocket();
  }

  private initSocket() {
      this.socket.initSockect();
      this.socket.getNotification().subscribe(resp => {
          this.notiBack = resp.installation;
          this.notiIncidence = resp.incidence;
      });
      this.socket.getAlerts().subscribe(resp => {
        this.notifications = resp.notis.filter(n => n.rols.split(',').includes(this.userRol.toString()));

        this.notiCount = this.notifications.filter(n =>
            (n.users_read === null || (n.users_read !== null && !n.users_read.split(',').includes(this.userId.toString())))).length;
      });
  }

  public viewNotifications() {
    this.end.toggle();
    const _notis = this.notifications.filter(n =>
        (n.users_read === null || (n.users_read !== null && !n.users_read.split(',').includes(this.userId.toString()))))
        .map(n => { return { user_id: this.userId, notification_id: n.id } });

    this.socket.setNotificationsReaded(_notis);
  }

  ngOnInit(): void {
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar-container ');

    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac() && !this.compactSidebar && this.layoutDir != 'rtl') {
      const ps = new PerfectScrollbar(elemSidebar, {
                              wheelSpeed: 2,
                              suppressScrollX: true
                            });
    }

    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.url = event.url;
      if (this.isOver()) {
        this.sidemenu.close();
      }

      if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac() && !this.compactSidebar && this.layoutDir != 'rtl') {
        // Ps.update(elemContent);
      }
    });
    this.horizontalMenuItems.generateMenu();
    this.menuItems.generateMenu();
  }

    logOut() {
      this.socket.disconnect();
      this.usersService.logout();
      this.router.navigate(['auth', 'login']);
    }

  @HostListener('click', ['$event'])
  onClick(e: any) {
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar-container ');
    setTimeout(() => {
      if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac() && !this.compactSidebar && this.layoutDir != 'rtl') {
        const ps = new PerfectScrollbar(elemSidebar, {
                              wheelSpeed: 2,
                              suppressScrollX: true
                            });
      }
    }, 350);
  }

  ngOnDestroy() {
    this._router.unsubscribe();
  }

  isOver(): boolean {
    if (this.url === '/apps/messages' || this.url === '/apps/calendar' || this.url === '/apps/media' || this.url === '/maps/leaflet') {
      return true;
    } else {
      return window.matchMedia(`(max-width: 960px)`).matches;
    }
  }

  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  menuMouseOver(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
      this.sidemenu.mode = 'over';
    }
  }

  menuMouseOut(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
      this.sidemenu.mode = 'side';
    }
  }
  
  menuToggleFunc()
  {
    this.sidemenu.toggle();
    
    if(this.collapsedClass == 'side-panel-opened')
    {
        this.collapsedClass = 'side-panel-closed';
    }
    else
    {
        this.collapsedClass= 'side-panel-opened';
    }
  }

  changeMenuLayout(value)
  {
    console.log(value)
    if(value)
    {
      this.menuLayout = 'top-menu';
    }
    else
    {
      this.menuLayout = 'vertical-menu';
    }
  }
  
  onSelectSidebarImage(selectedClass, event)
  {
    this.selectedSidebarImage = selectedClass;
  }
  
  onSelectedSidebarColor(selectedClass)
  {
    this.selectedSidebarColor = selectedClass;
  }
  
  onSelectedHeaderColor(selectedClass)
  {
    this.selectedHeaderColor = selectedClass;
  }

  isBgActive(value)
  {
    if(value == this.selectedSidebarImage)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  isSidebarActive(value)
  {
    if(value == this.selectedSidebarColor)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  isHeaderActive(value)
  {
    if(value == this.selectedHeaderColor)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  addMenuItem(): void {
    this.menuItems.add({
      state: 'menu',
      name: 'MENU',
      type: 'sub',
      icon: 'trending_flat',
      children: [
        {state: 'menu', name: 'MENU'},
        {state: 'timelmenuine', name: 'MENU'}
      ]
    });
  }
}
