import { Injectable } from '@angular/core';
import { HistoryModel } from '../models/history.model';
import { HttpClient } from '@angular/common/http';
import { ConfigApi } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class HistoryService {

    private URL_API: string;

    constructor(private http: HttpClient, private config: ConfigApi) {
        this.URL_API = config.URL_SERVICES + '/history/';
    }

    add(history: HistoryModel) {
        return this.http.post(this.URL_API, history, this.config.OPTIONS_HTTP);
    }

    getHistoriesOpportunity(id: number) {
        return this.http.get(this.URL_API + 'opportunity/' + id, this.config.OPTIONS_HTTP);
    }

    getHistoriesCustomer(id: number) {
        return this.http.get(this.URL_API + 'customer/' + id, this.config.OPTIONS_HTTP);
    }

    getHistoriesIncidence(id: number) {
        return this.http.get(this.URL_API + 'incidence/' + id, this.config.OPTIONS_HTTP);
    }

    getHistoriesInstallation(id: number) {
        return this.http.get(this.URL_API + 'installation/' + id, this.config.OPTIONS_HTTP);
    }

    getHistoriesBudget(id: number) {
        return this.http.get(this.URL_API + 'budget/' + id, this.config.OPTIONS_HTTP);
    }

}
