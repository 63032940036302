import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from './services/guards/auth.guard';

import { UsersEditComponent } from './pages/users/users-edit/users-edit.component';

export const AppRoutes: Routes = [
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full',
            },
            {
                path: 'home',
                loadChildren: './dashboard/dashboard.module#DashboardModule',
            }, {
                path: 'incidences',
                loadChildren: './pages/incidences/incidences.module#IncidencesModule',
                canLoad: [AuthGuard]
            }, {
                path: 'customers',
                loadChildren: './pages/customers/customers.module#CustomersModule',
                canLoad: [AuthGuard]
            }, {
                path: 'documents',
                loadChildren: './pages/documents/documents.module#DocumentsModule',
                canLoad: [AuthGuard]
            }, {
                path: 'opportunities',
                loadChildren: './pages/opportunities/opportunities.module#OpportunitiesModule',
                canLoad: [AuthGuard]
            }, {
                path: 'orders',
                loadChildren: './pages/orders/orders.module#OrdersModule',
                canLoad: [AuthGuard]
            }, {
                path: 'users',
                loadChildren: './pages/users/users.module#UsersModule',
                canLoad: [AuthGuard]
            }, {
                path: 'backoffice',
                loadChildren: './pages/backoffice/backoffice.module#BackofficeModule',
                canLoad: [AuthGuard]
            },
            {
                path: 'install',
                loadChildren: './pages/install/install.module#InstallModule',
                canLoad: [AuthGuard]
            },
            {
                path: 'commissions',
                loadChildren: './pages/commissions/commissions.module#CommissionsModule',
                canLoad: [AuthGuard]

            },
            {
                path: 'tools',
                loadChildren: './pages/tools/tools.module#ToolsModule',
                canLoad: [AuthGuard]
            },
            {
                path: 'discount',
                loadChildren: './pages/discounts/discounts.module#DiscountsModule',
                canLoad: [AuthGuard]

            },
          
        ]
    }, {
        path: '',
        component: AuthLayoutComponent,
        // canActivate: [true],
        children: [
            {
                path: 'auth',
                loadChildren: './session/session.module#SessionModule'
            }, {
                path: 'error',
                loadChildren: './error/error.module#ErrorModule'
            }]
    }, {
        path: '**',
        redirectTo: 'session/404'
    }
];
