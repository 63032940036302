export class DocumentModel {

    constructor(
        public name: string,
        public description: string = null,
        public file: File = null,
        public category_id: number = null,
        public customer_id: number = null,
        public id: number = null
    ) { }

}
