import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CommunitiesService {

    public communities = [
        {
            id: -1,
            name: 'Datos erróneos'
        },
        {
            id: 1,
            name: 'Andalucía'
        },
        {
            id: 2,
            name: 'Cataluña'
        },
        {
            id: 3,
            name: 'Comunidad de Madrid'
        },
        {
            id: 4,
            name: 'Comunidad Valenciana'
        },
        {
            id: 5,
            name: 'Galicia'
        },
        {
            id: 6,
            name: 'Castilla y León'
        },
        {
            id: 7,
            name: 'País Vasco'
        },
        {
            id: 8,
            name: 'Canarias'
        },
        {
            id: 9,
            name: 'Castilla-La Mancha'
        },
        {
            id: 10,
            name: 'Región de Murcia'
        },
        {
            id: 11,
            name: 'Aragón'
        },
        {
            id: 12,
            name: 'Islas Baleares'
        },
        {
            id: 13,
            name: 'Extremadura'
        },
        {
            id: 14,
            name: 'Principado de Asturias'
        },
        {
            id: 15,
            name: 'Comunidad Foral de Navarra'
        },
        {
            id: 16,
            name: 'Cantabria'
        },
        {
            id: 17,
            name: 'La Rioja'
        },
        {
            id: 18,
            name: 'Melilla'
        },
        {
            id: 19,
            name: 'Ceuta'
        }
    ]

    private _provinces = [
        {id: 1, name: "A Coruña", id_community: 5},
        {id: 2, name: "Álava", id_community: 7},
        {id: 3, name: "Albacete", id_community: 9},
        {id: 4, name: "Alicante", id_community: 4},
        {id: 5, name: "Almería", id_community: 1},
        {id: 6, name: "Asturias", id_community: 14},
        {id: 7, name: "Ávila", id_community: 6},
        {id: 8, name: "Badajoz", id_community: 13},
        {id: 9, name: "Baleares", id_community: 12},
        {id: 10, name: "Barcelona", id_community: 2},
        {id: 11, name: "Burgos", id_community: 6},
        {id: 12, name: "Cáceres", id_community: 13},
        {id: 13, name: "Cádiz", id_community: 1},
        {id: 14, name: "Cantabria", id_community: 16},
        {id: 15, name: "Castellón", id_community: 4},
        {id: 16, name: "Ciudad Real", id_community: 9},
        {id: 17, name: "Córdoba", id_community: 1},
        {id: 18, name: "Cuenca", id_community: 9},
        {id: 19, name: "Girona", id_community: 2},
        {id: 20, name: "Granada", id_community: 1},
        {id: 21, name: "Guadalajara", id_community: 9},
        {id: 22, name: "Gipuzkoa", id_community: 7},
        {id: 23, name: "Huelva", id_community: 1},
        {id: 24, name: "Huesca", id_community: 11},
        {id: 25, name: "Jaén", id_community: 1},
        {id: 26, name: "La Rioja", id_community: 17},
        {id: 27, name: "Las Palmas", id_community: 8},
        {id: 28, name: "León", id_community: 6},
        {id: 29, name: "Lleida", id_community: 2},
        {id: 30, name: "Lugo", id_community: 5},
        {id: 31, name: "Madrid", id_community: 3},
        {id: 32, name: "Málaga", id_community: 1},
        {id: 33, name: "Murcia", id_community: 10},
        {id: 34, name: "Navarra", id_community: 15},
        {id: 35, name: "Ourense", id_community: 5},
        {id: 36, name: "Palencia", id_community: 6},
        {id: 37, name: "Pontevedra", id_community: 5},
        {id: 38, name: "Salamanca", id_community: 6},
        {id: 39, name: "Segovia", id_community: 6},
        {id: 40, name: "Sevilla", id_community: 1},
        {id: 41, name: "Soria", id_community: 6},
        {id: 42, name: "Tarragona", id_community: 2},
        {id: 43, name: "Santa Cruz de Tenerife", id_community: 8},
        {id: 44, name: "Teruel", id_community: 11},
        {id: 45, name: "Toledo", id_community: 9},
        {id: 46, name: "Valencia", id_community: 4},
        {id: 47, name: "Valladolid", id_community: 6},
        {id: 48, name: "Vizcaya", id_community: 7},
        {id: 49, name: "Zamora", id_community: 6},
        {id: 50, name: "Zaragoza", id_community: 11}
    ]

    /**
     * Retorna la comunidad según el nombre de la provincia
     * @param province_id El id de la provincia
     * @returns Retorna el id de la comunidad
     */
    public getCommunityId(province_id: number): number {
        let province = this._provinces.find(p => p.id === province_id);

        if (typeof province !== 'undefined') {
            let community = this.communities.find(c => c.id === province.id_community);
            return (typeof province !== 'undefined' ? community.id : -1)
        } else {
            return -1;
        }
    }

    /**
     * Retornar el listado de provincias configuradas.
     */
    get provinces(): {id: number, name: string, id_community: number}[] {
        return this._provinces;
    }

}
