import { Injectable } from '@angular/core';
import { USER_ROL, UsersService } from '../../services/users.service';

export interface BadgeItem {
    type: string;
    value: string;
}

export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
}

export interface Menu {
    state: string | string[];
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    children?: ChildrenItems[];
}

const MENUITEMS: Menu[] = [
    {
        state: 'home',
        name: 'Inicio',
        type: 'link',
        icon: 'home'
    },
    {
        state: 'opportunities',
        name: 'Oportunidades',
        type: 'sub',
        icon: 'star',
        children: [
            { state: 'opportunitieslist', name: 'Listado oportunidades' },
            { state: 'opportunitiesedit', name: 'Nueva oportunidad' },
        ]
    }, {
        state: 'customers',
        name: 'Clientes',
        type: 'sub',
        icon: 'people',
        children: [
            { state: 'customerslist', name: 'Listado clientes' },
            { state: 'customersedit', name: 'Nuevo cliente' },
        ]
    },
    {
        state: 'orders',
        name: 'Pedidos',
        type: 'sub',
        icon: 'local_grocery_store',
        children: [
            { state: 'orderslist', name: 'Listado pedidos' },
            { state: 'orderstrunkedit', name: 'Trunk' },
            { state: 'orderscloudedit', name: 'Cloud' },
            { state: 'ordersgsmedit', name: 'GSM' },
            { state: 'orderservicesedit', name: 'Otros Servicios' }
        ]
    },
    {
        state: 'install',
        name: 'Backoffice',
        type: 'sub',
        icon: 'assignment',
        children: [
            { state: 'install', name: 'Back-Office' },
            { state: 'install/product', name: 'Agregar producto' },
            { state: 'install/product-list', name: 'Administrar productos' },
        ]
    },
    {
        state: 'incidences',
        name: 'Incidencias',
        type: 'sub',
        icon: 'error',
        children: [
            { state: 'incidenceslist', name: 'Listado incidencias' },
            { state: 'incidenceedit', name: 'Nueva incidencia' },
        ]
    },
    {
        state: 'tools',
        name: 'Herramientas',
        type: 'sub',
        icon: 'build',
        children: [
            { state: 'tools/workingpart', name: 'Parte de trabajo' },
            { state: 'tools/configurator', name: 'Configurador' },
        ]
    },
    {
        state: 'commissions',
        name: 'Comisiones',
        type: 'sub',
        icon: 'attach_money',
        children: [
            { state: 'commissionslist', name: 'Listado comisiones' },
            { state: 'commissionsedit', name: 'Nueva comision' },
        ]
    },
    {
        state: 'documents',
        name: 'Documentos',
        type: 'sub',
        icon: 'file_copy',
        children: [
            { state: 'documentslist', name: 'Listado documentos' },
            { state: 'documentsedit', name: 'Nuevo documento' },
        ]
    },
    {
        state: 'users',
        name: 'Usuarios',
        type: 'sub',
        icon: 'account_circle',
        children: [
            { state: 'userslist', name: 'Listado usuarios' },
            { state: 'usersedit', name: 'Nuevo usuario' },
        ]
    }

];


@Injectable()
export class MenuItems {

    private _MENUITEMS: any[];
    private MENUUSERS: any = {
        'backoffice': ['home', 'customers', 'orders', 'install', 'incidences', 'tools', 'commissions'],
        'distributor': ['home', 'opportunities', 'customers', 'orders', 'tools', 'commissions', 'documents'],
        'commercial': ['home', 'opportunities', 'customers', 'orders', 'incidences', 'tools', 'documents'],
        'technic': ['home', 'customers', 'install', 'incidences', 'tools']
    }

    constructor(public usersService: UsersService) { }

    public generateMenu() {
        const userApp: any = this.usersService.getUserApp();

        if (userApp.rol_id === USER_ROL.ADMIN) {
            this._MENUITEMS = MENUITEMS;
        } else if (userApp.rol_id === USER_ROL.BACKOFFICE) {
            this._MENUITEMS = MENUITEMS.filter(this.filterMenuByUser('backoffice').bind(this));
        } else if (userApp.rol_id === USER_ROL.TECHNIC) {
            MENUITEMS[2].type = 'link';
            this._MENUITEMS = MENUITEMS.filter(this.filterMenuByUser('technic').bind(this));
        } else if (userApp.rol_id === USER_ROL.DISRIBUTOR) {
            this._MENUITEMS = MENUITEMS.filter(this.filterMenuByUser('distributor').bind(this));
        } else if (userApp.rol_id === USER_ROL.COMMERCIAL) {
            this._MENUITEMS = MENUITEMS.filter(this.filterMenuByUser('commercial').bind(this));
        }
    }

    /**
     * Función callback del filtro de los puntos de menú según los permisos de cada perfil.
     * @param {sring} profile El nombre del perfil.
     * @return {boolean} Retorna true si el punto de menú pertenece al perfil indicado.
     */
    filterMenuByUser(profile: string) {
        return (m: any) => this.MENUUSERS[profile].includes(m.state);
    }

    getAll(): Menu[] {
        return this._MENUITEMS;
    }

    add(menu: Menu) {
        this._MENUITEMS.push(menu);
    }
}
