import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-tododialog',
    templateUrl: './tododialog.component.html',
    styles: []
})
export class ToDoDialogComponent {
    constructor(public dialogRef: MatDialogRef < ToDoDialogComponent >) { }
}
