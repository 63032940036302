import { Component, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ToDoModel } from '../../../models/todo.model';
import { UserModel } from '../../../models/user.model';
import { OpportunityModel } from '../../../models/opportunity.model';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatSnackBar, MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../../services/users.service';
import { OpportunityService } from '../../../services/opportunity.service';
import { ToDoService } from '../../../services/todo.service';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-create-todo-dialog',
    templateUrl: './create-todo.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})
export class AddToDoDialogComponent implements OnInit {

    formToDoAdd: FormGroup;
    todoModel: ToDoModel = null;
    userApp: UserModel;
    opportunityModel: OpportunityModel = null;
    deleteOrModified = true;
    subjects = ['VISITA', 'LLAMADA', 'GESTIÓN', 'EMAIL'];

    constructor(public dialogRef: MatDialogRef<AddToDoDialogComponent>, private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute, private usersService: UsersService, private opportunityService: OpportunityService,
        private snackBar: MatSnackBar, private todoService: ToDoService, @Inject(MAT_DIALOG_DATA) public data: any,
        private adapter: DateAdapter<any>, private router: Router) {

        this.userApp = usersService.getUserApp();
        this.adapter.setLocale('es-es');
    }


    /**
     * El evento para guardar el formulario de todos
     * @event submit
     */
    addToDoForm() {

        const controls: { [key: string]: AbstractControl } = this.formToDoAdd.controls;

        this.todoModel.date_create = this.getDateFormat();
        this.todoModel.subject = controls.subject.value;
        this.todoModel.message = controls.message.value;
        this.todoModel.date_todo = this.getDateFormat(this.getDateFormatTodo(controls.date_todo.value,controls.time_todo.value));
        this.todoModel.user_id = this.userApp.id;
        if(this.activatedRoute.snapshot['_routerState'].url.substr(33) != ""){
            this.todoModel.opportunity_id = this.activatedRoute.snapshot['_routerState'].url.substr(33);
        }else{
            this.todoModel.opportunity_id = null;
        }


        if (this.todoModel.id === null) {
            this.todoService.add(this.todoModel).subscribe(this.messageSubscribe.bind(this));
        } else {
            this.todoService.update(this.todoModel).subscribe(this.messageSubscribe.bind(this));
        }
        this.dialogRef.close();
    }

    private messageSubscribe(resp: any) {
    console.log(resp);
        if (resp.ok) {
            this.snackBar.open('El todo se ha guardado correctamente', 'CERRAR', { duration: 3000 });
        } else {
            this.snackBar.open('No se ha podido guardar el todo', 'CERRAR', { duration: 3000 });
        }
    }

    ngOnInit() {
        this.formToDoAdd = this.formBuilder.group({
            subject: ['', Validators.required],
            message: [''],
            time_todo: [''],
            date_todo: ['']
        });
        
        this.initValues();

    }

    private getDateFormat(_date:Date = null) {
        const date = _date !== null ? _date : new Date();
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' '
            + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    }

    private getDateFormatTodo(dateOut, timeOut) {
        const date = new Date(dateOut);
        date.setHours(timeOut.substr(0, 2));
        date.setMinutes(timeOut.substr(3, 2));
        return date;
    }

    /**
     * Inicializamos los valores del formulario.
     */
    initValues() {
        if (this.data == null) {
            this.todoModel = new ToDoModel(null, null, '', '', null, null, 0, null, null);
        } else {
            this.todoModel = new ToDoModel(this.data.id, this.data.date, this.data.subject,
                this.data.date_todo, this.data.message, this.data.editable, this.data.user_id,
                this.data.opportunity_id, this.data.customer_id);
        }

        if (this.todoModel.id !== null) {
            this.initValuesForm();
        }
    }

    /**
     * Inicializamos los valores del formulario.
     */
    initValuesForm() {
        const controls = this.formToDoAdd.controls;
        const dateTime = this.todoModel.date_todo.split(' ');
        const _date = dateTime[0].split('-');
        const date = new Date(`${_date[2]}/${_date[1]}/${_date[0]} ${dateTime[1]}`);
        // Rellenamos los valores
        controls.message.setValue(this.todoModel.message);
        controls.subject.setValue(this.todoModel.subject);
        controls.date_todo.setValue(date);
        controls.time_todo.setValue(`${('0' + date.getHours()).substr(-2)}:${('0' + date.getMinutes()).substr(-2)}`);
    }



    /*
    * Borrar del todo
    */
    deleteToDo() {
        this.todoService.delete(this.todoModel).subscribe(this.messageSubscribeDeleteToDo.bind(this));
        this.dialogRef.close();
    }

    private messageSubscribeDeleteToDo(resp: any) {
        if (resp.ok) {
            this.snackBar.open('El todo se ha borado correctamente', 'CERRAR', { duration: 3000 });
        } else {
            this.snackBar.open('No se ha podido borrar el todo', 'CERRAR', { duration: 3000 });
        }
    }
}
