import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DocumentModel } from '../../../models/document.model';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { DocumentService } from '../../../services/document.service';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { UsersService } from '../../../services/users.service';
import { UserModel } from '../../../models/user.model';

@Component({
    selector: 'app-document-edit-modal',
    templateUrl: './document-edit-modal.component.html',
    styles: []
})
export class DocumentEditModalComponent implements OnInit {

    formSubtitle = 'Crear';
    formDocumentEdit: FormGroup;
    documentModel: DocumentModel = null;
    userApp: UserModel;
    fileUpload: File = null;

    categories: any[] = [
        { value: 'Presentación', id: 1 },
        { value: 'Oferta', id: 2 },
        { value: 'Tarifas', id: 3 }
    ];

    constructor(public dialogRef: MatDialogRef<DocumentEditModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder, private documentService: DocumentService, private snackBar: MatSnackBar,
        private usersService: UsersService) {

        this.userApp = usersService.getUserApp();

        this.documentModel = data.documentModel !== null ? data.documentModel : new DocumentModel('', '', null, null, data.idCustomer);
    }

    /**
     * Inicializamos los valores del formulario.
     */
    initValuesForm() {
        const controls = this.formDocumentEdit.controls;

        // Rellenamos los valores
        controls.name.setValue(this.documentModel.name);
        controls.description.setValue(this.documentModel.description);
        controls.category_id.setValue(this.documentModel.category_id);
    }

    ngOnInit() {
        this.formDocumentEdit = this.formBuilder.group({
            name: ['', Validators.required],
            description: ['']
        });
    }

    setFile(file: File) {
        this.fileUpload = !file ? null : file;
    }

    /**
      * El evento para guardar el formulario
      * @event submit
      */
    onSubmit() {
        const controls: { [key: string]: AbstractControl } = this.formDocumentEdit.controls;

        for (const key in controls) {
            if (this.documentModel.hasOwnProperty(key)) {
                this.documentModel[key] = controls[key].value;
            }
        }
        this.documentModel.file = this.fileUpload;

        if (this.documentModel.id === null) {
            this.documentService.add(this.documentModel)
            .then(this.messageSubscribe.bind(this))
            .catch(this.messageSubscribe.bind(this));
        } else {
            this.documentService.update(this.documentModel)
            .then(this.messageSubscribe.bind(this))
            .catch(this.messageSubscribe.bind(this));
        }
    }

    private messageSubscribe(resp: any) {
        if (resp.ok) {
            this.dialogRef.close();
            this.snackBar.open('El documento se ha ' +
            (this.documentModel.id === null ? 'guardado' : 'actualizado') + ' correctamente', 'CERRAR', { duration: 3000 });
        } else {
            this.snackBar.open('No se ha podido ' +
            (this.documentModel.id === null ? 'guardar' : 'actualizar') + ' el documento', 'CERRAR', { duration: 3000 });
        }
    }

}
