import { Component, OnInit, Input } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { HistoryService } from '../../services/history.service';

@Component({
    selector: 'app-tab-history',
    templateUrl: './tab-history.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
    ]
})
export class TabHistoryComponent implements OnInit {

    @Input() type: string;
    @Input() id: number;

    user_select: number = null;
    date_from: any = null;
    date_to: any = null;
    users: { id: number, name: string }[] = [];

    rows: any[] = [];
    allRows: any[] = [];
    filterRows: any[] = [];
    count = 0;
    offset = 0;
    limit = 20;
    
    columns: any[] = [];

    constructor(private historyService: HistoryService) { }

    ngOnInit() {
        this.generateHistories();
    }

    /**
     * Según el tipo de histórico a mostrar realizamos la petición pertinente
     */
    private generateHistories() {
        if (this.type === 'opportunity') {
            this.historyService.getHistoriesOpportunity(this.id).subscribe(this.responseHistories.bind(this));
        } else if (this.type === 'customer') {
            this.historyService.getHistoriesCustomer(this.id).subscribe(this.responseHistories.bind(this));
        } else if (this.type === 'incidence') {
            this.historyService.getHistoriesIncidence(this.id).subscribe(this.responseHistories.bind(this));
        } else if (this.type === 'installation') {
            this.historyService.getHistoriesInstallation(this.id).subscribe(this.responseHistories.bind(this));
        } else if (this.type === 'budget') {
            this.historyService.getHistoriesBudget(this.id).subscribe(this.responseHistories.bind(this));
        }
    }

    /**
     * Acción de la respuesta de la petición del listado de histórico
     * @param resp El objeto resultante de la petición
     */
    private responseHistories(resp: any) {
        this.allRows = resp.history;
        this.filterRows = resp.history;
        this.count = this.allRows.length;
        this.getListUsers();
        this.page();
        this.setColumns();
    }

    /**
     * Rellena el desplegable de los usuarios con los usuarios que ha realizado algún histórico
     */
    private getListUsers() {
        const idIncludes: number[] = [];
        this.allRows.forEach(_row => {
            const tmp = { id: _row.user_id, name: _row.name };
            if (!idIncludes.includes(tmp.id)) {
                idIncludes.push(tmp.id);
                this.users.push(tmp);
            }
        });
    }

    /**
     * Paginación de la tabla
     */
    private page() {
        const start = this.offset * this.limit;
        const end = start + this.limit > this.count ? this.count : start + this.limit;
        const rows = [];
        for (let i = start; i < end; i++) {
            rows.push(this.filterRows[i]);
        }
        this.rows = rows;
    }

    /**
     * Evento al cambiar de página en la tabla.
     * @param pageInfo Los datos de la página a mostrar.
     */
    public onPage(pageInfo) {
        this.offset = pageInfo.offset;
        this.page();
    }

    /**
     * Aplicar el filtro según los campos rellenados.
     */
    applyFilter() {
        this.filterRows = this.allRows;
        if (this.user_select !== null) {
            this.filterRows = this.filterRows.filter(h => h.user_id === this.user_select);
        }

        if (this.date_from !== null) {
            this.filterRows = this.filterRows.filter((dt: any) => {
                const tmp_date = new Date(this.date_from._i.year + '/' + (this.date_from._i.month + 1) + '/' + this.date_from._i.date);
                return new Date(dt.date_real) >= tmp_date;
            });
        }
        if (this.date_to !== null) {
            this.filterRows = this.filterRows.filter((dt: any) => {
                const tmp_date = new Date(
                    this.date_to._i.year + '/' + (this.date_to._i.month + 1) + '/' + this.date_to._i.date + ' 23:59:59');
                return new Date(dt.date_real) <= tmp_date;
            });
        }

        this.count = this.filterRows.length;
        this.offset = 0;
        this.page();
        this.setColumns();
    }

    /**
     * Reiniciar el filtro y los valores de la tabla.
     */
    resetFilter() {
        this.user_select = null;
        this.date_from = null;
        this.date_to = null;
        this.filterRows = this.allRows;
        this.count = this.filterRows.length;
        this.offset = 0;
        this.page();
        this.setColumns();
    }
    private setColumns() {
        this.columns = [
            { name: 'Fecha realización', prop: 'date_create', maxWidth: 250 },
            { name: 'Usuario', prop: 'name', maxWidth: 300 },
            { name: 'Asunto', prop: 'subject' }
        ];

        const hasBudgetId = this.allRows.some(row => row.budget_id);
        if (hasBudgetId) {
            this.columns.unshift({ name: 'Budget Id', prop: 'budget_id', maxWidth: 100 });
        }
    }
}
