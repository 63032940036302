import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../services/users.service';
import { UserModel } from '../../models/user.model';
import { DelegationModel } from '../../../app/models/delegation.model';
import { DelegationService } from '../../services/delegation.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';
import { OptionModel } from '../../models/option.model';

@Component({
    selector: 'app-create-delegation-dialog',
    templateUrl: './create-delegation.html'
})

export class AddDelegationDialogComponent implements OnInit {

    formDelegationAdd: FormGroup;
    delegationModel: DelegationModel = null;
    userApp: UserModel;
    typeRoad: OptionModel[];
    hiringInformationToModel: any;
    receiveHiringInformation: any;
    receiveInformationFix: any;
    relevantTechnicalDataToModel: any;
    receiveRelevantTechnicalDataToModel: any;

    constructor(public dialogRef: MatDialogRef<AddDelegationDialogComponent>, private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute, private usersService: UsersService, private snackBar: MatSnackBar,
        private delegationService: DelegationService, @Inject(MAT_DIALOG_DATA) public data: any) {

        this.userApp = usersService.getUserApp();
        this.typeRoad = data.typeRoad;
        this.delegationModel = data.delegation;

    }

    /**
     * El evento para guardar el formulario de delegación
     * @event submit
     */
    addDelegationForm() {

        const controls: { [key: string]: AbstractControl } = this.formDelegationAdd.controls;

        this.delegationModel.direction = controls.direction.value;
        this.delegationModel.type_road_id = controls.type_road_id.value;
        this.delegationModel.city = controls.city.value;
        this.delegationModel.cp = controls.cp.value;
        this.delegationModel.provincie = controls.provincie.value;
        this.delegationModel.contact_person = controls.contact_person.value;
        this.delegationModel.contact_phone = controls.contact_phone.value;
        this.delegationModel.contact_mobile = controls.contact_mobile.value;
        this.delegationModel.contact_email = controls.contact_email.value;
        this.delegationModel.customer_id = this.activatedRoute.snapshot['_routerState'].url.substr(25);

        // part of Hiring Information

        if (this.receiveHiringInformation != null) {
            this.delegationModel.n_trunk_cloud = this.receiveHiringInformation.n_trunk_cloud;
            this.delegationModel.package_trunk = this.receiveHiringInformation.package_trunk;
            if (this.receiveHiringInformation.cp_customer === true) {
                this.delegationModel.cp_customer = 1;
            } else {
                this.delegationModel.cp_customer = 0;
            }
            if (this.receiveHiringInformation.cp_model_id === '') {
                this.delegationModel.cp_model_id = null;
            } else {
                this.delegationModel.cp_model_id = this.receiveHiringInformation.cp_model_id;
            }
            this.delegationModel.cp_version = this.receiveHiringInformation.cp_version;
            this.delegationModel.date_create_service = this.getDateDMAFormat(this.receiveHiringInformation.date_create_service);
            this.delegationModel.cp_value = this.receiveHiringInformation.cp_value;
            this.delegationModel.method_pay = this.receiveHiringInformation.method_pay;
            this.delegationModel.monthly_fee = this.receiveHiringInformation.monthly_fee;
            if (this.receiveHiringInformation.trunk_sip_id === '') {
                this.delegationModel.trunk_sip_id = null;
            } else {
                this.delegationModel.trunk_sip_id = this.receiveHiringInformation.trunk_sip_id;
            }
            this.delegationModel.renting_contract = this.receiveHiringInformation.renting_contract;
            this.delegationModel.date_start_contract = this.getDateDMAFormat(this.receiveHiringInformation.date_start_contract);
            this.delegationModel.date_end_contract = this.getDateDMAFormat(this.receiveHiringInformation.date_end_contract);
            if (this.receiveHiringInformation.maintenance_id === '') {
                this.delegationModel.maintenance_id = null;
            } else {
                this.delegationModel.maintenance_id = this.receiveHiringInformation.maintenance_id;
            }

        } else {
            this.delegationModel.date_create_service = this.getDateDMAFormat(this.delegationModel.date_create_service);
            this.delegationModel.date_start_contract = this.getDateDMAFormat(this.delegationModel.date_start_contract);
            this.delegationModel.date_end_contract = this.getDateDMAFormat(this.delegationModel.date_end_contract);
        }

        // part of Relevant Technical Data
        if (this.receiveRelevantTechnicalDataToModel != null) {
            this.delegationModel.switchboard_id = this.receiveRelevantTechnicalDataToModel.switchboard_id;
            this.delegationModel.external_connectivity_id = this.receiveRelevantTechnicalDataToModel.external_connectivity_id;
            this.delegationModel.gb_contracted = this.receiveRelevantTechnicalDataToModel.gb_contracted;
            this.delegationModel.operator = this.receiveRelevantTechnicalDataToModel.operator;
            this.delegationModel.server = this.receiveRelevantTechnicalDataToModel.server;
            this.delegationModel.router = this.receiveRelevantTechnicalDataToModel.router;
            this.delegationModel.ip_order_tranck = this.receiveRelevantTechnicalDataToModel.ip_order_tranck;
            this.delegationModel.external_ip_address = this.receiveRelevantTechnicalDataToModel.external_ip_address;
            this.delegationModel.remote_connection_type_id = this.receiveRelevantTechnicalDataToModel.remote_connection_type_id;
            this.delegationModel.technical_data = this.receiveRelevantTechnicalDataToModel.technical_data;
            this.delegationModel.internal_resources_connectivity_id =
                 this.receiveRelevantTechnicalDataToModel.internal_resources_connectivity_id;
            this.delegationModel.version_switchboard = this.receiveRelevantTechnicalDataToModel.version_switchboard;
        }

        if (this.delegationModel.id === null) {
            this.delegationService.add(this.delegationModel).subscribe(this.messageSubscribe.bind(this));
        } else {

            this.delegationService.update(this.delegationModel).subscribe(this.messageSubscribeUpdate.bind(this));
        }
        this.dialogRef.close();
    }

    /** Format date for field date db */
    private getDateDMAFormat(dateReceive) {
        if (dateReceive !== '' && dateReceive !== null) {
            const date = dateReceive !== null ? new Date(dateReceive) : new Date();
            let result = `${date.getFullYear()}-${this.addZeroUnit(date.getMonth() + 1)}-${this.addZeroUnit(date.getDate())} `;
            result += `${this.addZeroUnit(date.getHours())}:${this.addZeroUnit(date.getMinutes())}:${this.addZeroUnit(date.getSeconds())}`;
            return result
        } else {
            return null;
        }

    }

    private addZeroUnit(value: number) {
        return (value < 10 ? '0' : '') + value;
    }

    private messageSubscribe(resp: any) {
        if (resp.ok) {
            this.snackBar.open('La delegación se ha guardado correctamente', 'CERRAR', { duration: 3000 });
        } else {
            this.snackBar.open('No se ha podido guardar la delegación', 'CERRAR', { duration: 3000 });
        }
    }

    private messageSubscribeUpdate(resp: any) {
        console.log(resp);
        if (resp.ok) {
            this.snackBar.open('La delegación se ha actualizado correctamente', 'CERRAR', { duration: 3000 });
        } else {
            this.snackBar.open('No se ha podido actualizado la delegación', 'CERRAR', { duration: 3000 });
        }
    }

    ngOnInit() {
        this.formDelegationAdd = this.formBuilder.group({
            type_road_id: ['', Validators.required],
            direction: ['', Validators.required],
            city: ['', Validators.required],
            date_todo: [''],
            provincie: [''],
            cp: [''],
            contact_person: ['', Validators.required],
            contact_email: [''],
            contact_phone: [''],
            contact_mobile: [''],

        }, {
                validator: this.matchContact
            });

        this.hiringInformationToModel = this.delegationModel;
        this.relevantTechnicalDataToModel = this.hiringInformationToModel;

        this.formDelegationAdd.controls['contact_email'].markAsTouched();
        this.formDelegationAdd.controls['contact_phone'].markAsTouched();
        this.formDelegationAdd.controls['contact_mobile'].markAsTouched();

        this.initValuesForm();

    }

    private matchContact(AC: AbstractControl): { [s: string]: boolean } | null {
        if ((AC.get('contact_email').value == null || AC.get('contact_email').value === '') &&
            (AC.get('contact_phone').value == null || AC.get('contact_phone').value === '') &&
            (AC.get('contact_mobile').value == null || AC.get('contact_mobile').value === '')) {
            AC.get('contact_email').setErrors({ nocontact: true });
            AC.get('contact_phone').setErrors({ nocontact: true });
            AC.get('contact_mobile').setErrors({ nocontact: true });
        } else {
            AC.get('contact_email').setErrors(null);
            AC.get('contact_phone').setErrors(null);
            AC.get('contact_mobile').setErrors(null);
            return null;
        }
    }

    receiveMessage($event) {
        this.receiveHiringInformation = $event;
    }

    receiveMessageRelevantTechnicalData($event) {
        this.receiveRelevantTechnicalDataToModel = $event;
    }

    /**
     * Inicializamos los valores del formulario.
     */
    initValuesForm() {
        const controls: { [key: string]: AbstractControl } = this.formDelegationAdd.controls;

        for (const key in controls) {
            if (this.delegationModel.hasOwnProperty(key)) {
                controls[key].setValue(this.delegationModel[key]);
            }
        }

    }

    /*
    * Borrar del todo
    */
    deleteDelegation() {

        this.delegationService.delete(this.delegationModel).subscribe(this.messageSubscribeDeleteDelegation.bind(this));

    }

    private messageSubscribeDeleteDelegation(resp: any) {
        if (resp.ok) {
            this.snackBar.open('La delegación se ha borado correctamente', 'CERRAR', { duration: 3000 });
        } else {
            this.snackBar.open('No se ha podido borrar a degalación', 'CERRAR', { duration: 3000 });
        }
    }



}

