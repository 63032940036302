import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule, Http } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { DemoMaterialModule } from './shared/demo.module';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';

import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { SharedModule } from './shared/shared.module';

import { HiringInformationComponent } from './shared/hiring-information/hiring-information.component';
import { RelevantTechnicalDataComponent } from './shared/relevant-technical-data/relevant-technical-data.component';

import { OrdersTrunkEditComponent } from './pages/orders/orders-trunk-edit/orders-trunk-edit.component';
import { OrdersCloudEditComponent } from './pages/orders/orders-cloud-edit/orders-cloud-edit.component';
import { ViewCustomerOrderComponent } from './pages/orders/view-customer-order/view-customer-order.component';

import { ReactiveFormsModule } from '@angular/forms';
import { ToDoDialogComponent } from './shared/tododialog/tododialog.component';
import { MatDatepickerModule, MatBadgeModule } from '@angular/material';
import { AddToDoDialogComponent } from './pages/todos/todos-list/todos-list.component';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { DocumentEditModalComponent } from './pages/documents/document-edit-modal/document-edit-modal.component';

import { FileSelectDirective } from 'ng2-file-upload';
import { FileUploadModule } from 'ng2-file-upload';
import { DocumentsListDeleteDialogComponent } from './pages/documents/documents-list/documents-list.component';

import { PopupComponent } from './shared/popup/popup.component';
import { AddDelegationDialogComponent } from './pages/delegation/delegation.component';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DatePipe, registerLocaleData } from '@angular/common';


import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es-ES');


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        AddToDoDialogComponent,
        AddDelegationDialogComponent,
        ViewCustomerOrderComponent,
        ToDoDialogComponent,
        DocumentEditModalComponent,
        DocumentsListDeleteDialogComponent,
        PopupComponent,
        HiringInformationComponent,
        RelevantTechnicalDataComponent

    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        RouterModule.forRoot(AppRoutes),
        FormsModule,
        HttpModule,
        MatSidenavModule,
        MatInputModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatToolbarModule,
        MatTabsModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatSelectModule,
        MatBadgeModule,
        ReactiveFormsModule,
        MatCardModule,
        DemoMaterialModule,
        HttpClientModule,
        FileUploadModule,
        MatDatepickerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        FlexLayoutModule,
        AmazingTimePickerModule
    ],
    providers: [
        TranslateService,
        DatePipe,
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
        { provide: MAT_DATE_LOCALE, useValue: 'es-es' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
    ],
    entryComponents: [
        AddToDoDialogComponent,
        AddDelegationDialogComponent,
        ViewCustomerOrderComponent,
        ToDoDialogComponent,
        DocumentEditModalComponent,
        PopupComponent,
        DocumentsListDeleteDialogComponent,
        HiringInformationComponent,
        RelevantTechnicalDataComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
