import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { DelegationService } from '../../services/delegation.service';
import { DelegationModel } from '../../models/delegation.model';
import { MatListOption } from '@angular/material';


@Component({
    selector: 'app-list-address-order',
    templateUrl: './list-address-order.component.html'
})
export class ListAddressOrderComponent implements OnInit {

    @Input() customerId: number;
    @Input() delegationsSelected: number[] = [];
    @Input() disabled = false;
    @Output() addressChange = new EventEmitter<number[]>();

    public delegations: { idDelegation: DelegationModel, name: string, selected: boolean}[] = [];

    constructor(private delegationService: DelegationService) { }

    ngOnInit() {
        this.delegationService.getDelegationsByCustomerId(this.customerId).subscribe((resp: any) => {
            if (resp.ok) {
                resp.delegations.forEach((item: any) => {
                    this.delegations.push({
                        idDelegation: item.id,
                        name: `${item.type_road_name} ${item.direction}, ${item.city} (${item.provincie}) - ${item.cp}`,
                        selected: this.delegationsSelected.includes(item.id.toString())
                    })
                });
            }
        });
    }

    public selectionChange(event) {
        const delegations_id = [];
        event.source.selectedOptions.selected.forEach((option: MatListOption) => {
            delegations_id.push(option.value);
        });
        this.addressChange.emit(delegations_id);
    }

}
