import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UsersService, USER_ROL } from '../users.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    canRoute = false;
    routerForRol = {
        'backOffice': {
            'customersList': 'customers/customerslist',
            'customersEdit': 'customers/customersedit',
            'ordersList': 'orders/orderslist',
            'ordersTrunk': 'orders/orderstrunkedit',
            'ordersCloud': 'orders/orderscloudedit',
            'installList': 'install/installlist',
            'installEdit': 'install/installedit',
            'commissionsList': 'commissions/commissionslist',
            'commissionsEdit': 'commissions/commissionsedit',
            'incidencesList': 'incidences/incidenceedit',
            'incidenceEdit': 'commissions/commissionsedit',
            'workingPart': 'tools/workingpart/'
        },
        'technic': [
            'home/',
            'customers/customerslist',
            'customers/customersedit',
            'customers/',
            'incidences/incidenceslist',
            'incidences/',
            'install/installlist',
            'install/installedit',
            'install/',
            'tools/workingpart/'
        ],
        'comercial': [
            'home/',
            'customers/customerslist',
            'customers/customersedit',
            'customers/',
            'incidences/incidencesedit',
            'incidences/incidenceslist',
            'incidences/',
            'opportunities/',
            'opportunities/installlist',
            'opportunities/installedit',
            'customers/customerslist',
            'customers/customersedit',
            'customers/',
            'orders/customerslist',
            'orders/customersedit',
            'orders/',
            'documents/documentslist',
            'documents/documentsedit',
            'documents/',
            'tools/workingpart/'

        ],
        'distribuidor': [
            'home/',
            'customers/customerslist',
            'customers/customersedit',
            'customers/',
            'incidences/incidencesedit',
            'incidences/incidenceslist',
            'incidences/',
            'opportunities/',
            'opportunities/installlist',
            'opportunities/installedit',
            'customers/customerslist',
            'customers/customersedit',
            'customers/',
            'orders/customerslist',
            'orders/customersedit',
            'orders/',
            'documents/documentslist',
            'documents/documentsedit',
            'commissions/',
            'commissions/commissionslist',
            'commissions/commissionsedit',
            'documents/',
            'tools/',
            'tools/workingpart/',
            'tools/configurator/',
            'tools/configurator/budgets/'

        ]
    }

    constructor(public usersService: UsersService, public router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const userApp: any = this.usersService.getUserApp();
        if (this.usersService.isLoggedIn()) {
             if (next.url[0] !== undefined && next.url[0].toString() === 'opportunitiesedit') {
                if (userApp.rol_id !== USER_ROL.ADMIN && userApp.rol_id !== USER_ROL.COMMERCIAL && userApp.rol_id !== USER_ROL.DISRIBUTOR) {
                    this.router.navigate(['/home']);
                    return false;
                }
            }
            return true;
        }

        this.router.navigate(['/auth']);
        return false;
    }

    canLoad(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let path = '';
        // tslint:disable-next-line: forin
        for (const s in state) {
            path = path + state[s] + '/';
        }
        const pathUserPanel = 'users/usersedit/' + this.usersService.getUserApp().id + '/';
        // técnico rol 4
        if (path === pathUserPanel) {
            return true;
        } else if (this.usersService.getUserApp().rol_id === USER_ROL.TECHNIC) {
            // tslint:disable-next-line: forin
            for (const r in this.routerForRol.technic) {
                if (this.routerForRol.technic[r] === path
                    || (path.length > 'install/installedit/'.length && path.includes('install/installedit/'))
                    || (path.length > 'incidences/incidenceedit/'.length && path.includes('incidences/incidenceedit/')) ) {
                    return true;
                }
            }
            this.router.navigate(['/home']);
            return false;
        } else if (this.usersService.getUserApp().rol_id === USER_ROL.ADMIN) {
            return true;
        } else if (this.usersService.getUserApp().rol_id === USER_ROL.BACKOFFICE) {
            // tslint:disable-next-line: forin
            for (const r in this.routerForRol.backOffice) {
                if (this.routerForRol.backOffice[r] === path || path.includes('customers') || path.includes('order')
                    || path.includes('install') || path.includes('backoffice') || path.includes('incidence')
                    || path.includes('commissions')) {
                    return true;
                }
            }
        } else if (this.usersService.getUserApp().rol_id === USER_ROL.COMMERCIAL) {
            // tslint:disable-next-line: forin
            for (const r in this.routerForRol.comercial) {
                if (this.routerForRol.comercial[r] === path || path.includes('customers') || path.includes('order')
                    || path.includes('opportunities') || path.includes('incidence')
                    || path.includes('documents')) {
                    return true;
                }
            }
            this.router.navigate(['/home']);
            return false;
        } else if (this.usersService.getUserApp().rol_id === USER_ROL.DISRIBUTOR) {
            // tslint:disable-next-line: forin
            for (const r in this.routerForRol.distribuidor) {
                if (this.routerForRol.distribuidor[r] === path || path.includes('customers') || path.includes('order')
                    || path.includes('opportunities') || path.includes('incidence')
                    || path.includes('documents') || path.includes('tools') || path.includes('commissions')) {

                    return true;
                }
            }
            this.router.navigate(['/home']);
            return false;
        } else {
            this.router.navigate(['/home']);
            return false;

        }

        return true;
    }

}
