import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Baselist } from '../../../base/baselist';
import { DocumentModel } from '../../../models/document.model';
import { DocumentService } from '../../../services/document.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material';
import { environment } from '../../../../environments/environment';
import { RememberFilterService } from '../../../services/remember-filter.service';

@Component({
    selector: 'app-documents-list-delete-modal',
    templateUrl: './documents-list-delete-modal.html',
    styles: []
})
export class DocumentsListDeleteDialogComponent {
    constructor(public dialogRef: MatDialogRef<DocumentsListDeleteDialogComponent>) { }
}

@Component({
    selector: 'app-documents-list',
    templateUrl: './documents-list.component.html',
    styles: []
})
export class DocumentsListComponent extends Baselist implements OnInit {

    limit = 50;
    columns: any[] = [
        { name: 'ID', prop: 'id', maxWidth: 100 },
        { name: 'Nombre', prop: 'name' },
        { name: 'Descripción', prop: 'description' },
        { name: 'Categoria', prop: 'category', maxWidth: 250 }
    ];
    initOffset: number = null;

    myControlName = new FormControl();
    filteredOptionsName: Observable<string[]>;

    myControlDescription = new FormControl();
    filteredOptionsDescription: Observable<string[]>;

    name_select: any;
    names: string[] = [];
    description_select: any;
    descriptions: string[] = [];
    id_select: any;
    categoria_select: any;
    idDocuments: number[] = [];
    categories: {id: number, name: string}[] = [];

    allOpp: DocumentModel[] = [];

    constructor(public documentService: DocumentService, public router: Router, private dialog: MatDialog,
        private rememberFilterService: RememberFilterService) {
        super('/documents/documentsedit', router);

        this.filteredOptionsName = this.myControlName.valueChanges.pipe(
            startWith(''),
            map(value => this._filterName(value))
        );

        this.filteredOptionsDescription = this.myControlDescription.valueChanges.pipe(
            startWith(''),
            map(value => this._filterDescription(value))
        );
    }

    private _filterName(value: string): string[] {
        const filterValue = value !== null ? value.toLowerCase() : '';
        return this.names.filter(option => option.toLowerCase().includes(filterValue));
    }

    private _filterDescription(value: string): string[] {
        const filterValue = value !== null ? value.toLowerCase() : '';
        return this.descriptions.filter(option => option.toLowerCase().includes(filterValue));
    }

    ngOnInit() {
        this.documentService.getDocuments().subscribe((resp: any) => {
            this.getDataStorage();
            this.allOpp = resp.documents;
            this.applyFilter();
            this.initOffset = null;
            resp.documents.forEach((doc: any) => {
                this.names.push(doc.name);
                this.descriptions.push(doc.description);
                this.idDocuments.push(doc.id);
                if (typeof this.categories.find(c => c.id === doc.category_id) === 'undefined') {
                    this.categories.push({
                        id: doc.category_id,
                        name: doc.category
                    });
                }
            });
        });
    }

    /**
     * Filtro
     */
    applyFilter() {

        let filter_rows: DocumentModel[] = this.allOpp;

        if (typeof this.name_select !== 'undefined' && this.name_select !== null && this.name_select !== '') {
            filter_rows = filter_rows.filter(d => d.name === this.name_select);
        }
        if (typeof this.description_select !== 'undefined' && this.description_select !== null) {
            filter_rows = filter_rows.filter(d => d.description === this.description_select);
        }
        if (typeof this.id_select !== 'undefined' && this.id_select !== null) {
            filter_rows = filter_rows.filter(d => d.id === this.id_select);
        }
        if (typeof this.categoria_select !== 'undefined' && this.categoria_select !== null) {
            filter_rows = filter_rows.filter(d => d.category_id === this.categoria_select);
        }

        this.filterRows = filter_rows;
        this.offset = 0;
        this.initRowsFilter();
        this.setDataStorage();
    }

    /**
     * Guardamos los datos de la página y el filtro para que tengan persistencia.
     */
    public setDataStorage(page: number = 0) {
        this.rememberFilterService.setData('document', {
            filter: {
                name_select: this.name_select,
                description_select: this.description_select,
                id_select: this.id_select,
                categoria_select: this.categoria_select
            }
        })
    }

    /**
     * Recuperamos los datos de la página y el filtro guardados o los que son por defecto.
     */
    private getDataStorage() {
        const data = this.rememberFilterService.getData('document');
        if (data.filter !== null) {
            ['name_select', 'description_select', 'id_select', 'categoria_select'].forEach((key: string) => {
                if (data.filter.hasOwnProperty(key)) {
                    this[key] = data.filter[key];
                }
            });
        }
    }

    downloadDoc(doc: DocumentModel, event: Event) {
        event.stopPropagation();
        window.location.href = environment.url_api + '/document/download/' + doc.id;
    }

    deleteDoc(doc: DocumentModel, event: Event) {
        event.stopPropagation()
        const dialogRef = this.dialog.open(DocumentsListDeleteDialogComponent, {
            width: '250px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'SI') {
                this.documentService.delete(doc.id).subscribe((resp: any) => {
                    if (resp.ok) {
                        this.ngOnInit();
                    }
                });
            }
        });
    }

    resetFilter() {
        this.name_select = null;
        this.description_select = null;
        this.id_select = null;
        this.categoria_select = null;
        this.myControlName.setValue(null);
        this.myControlDescription.setValue(null);

        this.applyFilter();
    }

}
