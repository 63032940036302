import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DelegationService } from '../../services/delegation.service';

@Component({
    selector: 'app-view-delegation',
    templateUrl: './view-delegation.component.html',
    styleUrls: ['./view-delegation.component.scss']
})
export class ViewDelegationComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public delegation: any) {
        delegation.cp_customer = delegation.cp_customer === 1 ? 'SI' : 'NO';
        delegation.technical_data = delegation.technical_data !== null ?
        delegation.technical_data.replace(/\n/g, '<br>') : delegation.technical_data;
    }

}
